.chapterScroller {
  display: flex;
  overflow-x: scroll;
  //min-width: 60%;
  width: 100%;
  &_horizon {
    overflow-x: initial;
    overflow-y: scroll;
    flex-wrap: wrap;
    //min-height: 25vh;
  }
  &_watch {
    overflow-x: initial;
    overflow-y: scroll;
    flex-wrap: wrap;
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #aaa;
  }
}

@media screen and (max-width: 426px) {
  .chapterScroller {
    display: flex;
    overflow-x: initial;
    overflow-y: scroll;
    flex-wrap: wrap;
    height: 50vh;
    align-content: flex-start;
  }
}
