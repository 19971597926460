.input {
  //margin: 5px 0 15px 0;
  border: 1px solid transparent;
  border-radius: 2px;
  height: 30px;
  padding: 0 10px 0 10px;
  background-color: #f1f3f4;
  box-sizing: border-box;
  width: 100%;
  &:focus-visible {
    outline: none;
    border: 2px solid #4285f4;
  }
  &__cursorInitial {
    cursor: initial;
  }
}
