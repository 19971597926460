.bg {
  flex-direction: column;
  display: flex;
  padding: 0 10px;
  position: relative;
}

.pageContainer {
  display: flex;
  flex-direction: row;
}

.video {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.navigation {
  display: flex;
  flex-direction: column;
  width: 50%;

  &__chapters {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
  }
}

.videoContainer {
  width: auto;
  /* min-height: 600px; */
  height: 60vh;
  position: relative;
  overflow: hidden;
  z-index: 1;
  justify-content: center;
  display: flex;
  &__video {
    position: absolute;
    //width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.watchPagHeader {
  display: flex;
  align-items: center;

  &__uploadButton {
    width: 25%;
  }
  &__title {
    margin: 0 0 0 10px;
  }
}
