.smallButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #4285f4;
  border-radius: 2px;
  color: white;
  text-align: center;
  cursor: pointer;
  border: 1px solid transparent;
  line-height: 15px;
  height: 25px;
  outline: none;
  font-weight: bold;
  box-shadow: none;
  box-sizing: border-box;
  //width: 30%;
  font-size: 10px;
  margin-left: 5px;
  &_error {
    background: red;
  }
  &:hover {
    border: 1px solid transparent;
    -webkit-box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.15);
    background: #3c78dc;
    background: -webkit-linear-gradient(top, #3c7ae4, #3f76d3);
  }
  &:active {
    -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
