.navigationInnacurate {
  display: flex;
  flex-direction: row;
  //justify-content: space-between;
  margin-top: 10px;
}

@media screen and (max-width: 426px) {
  .navigationInnacurate {
    overflow-x: scroll;
  }
}
