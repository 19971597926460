.sceneScroller {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  height: 100%;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #aaa;
  }
}
