.modalContainer {
  //position: relative;
}

.modalBackground {
  width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.modal {
  background-color: white;
  position: absolute;
  top: calc((100vh - 200px) / 2);
  left: calc((100vw - 425px) / 2);
  right: calc((100vw - 425px) / 2);
  border-radius: 4px;
  padding: 10px;
  z-index: 3;
  &__title {
    margin-top: 0;
    margin-bottom: 10px;
  }
}

.off {
  display: none;
}

@media screen and (max-width: 425px) {
  .modal {
    left: calc((100vw - 300px) / 2);
    right: calc((100vw - 300px) / 2);
  }
}
