.newChapter {
  display: flex;
  flex-direction: column;
  width: 100%;
  //margin: 0 50px;
  //height: 10vh;
  justify-content: space-between;

  &__inputContainer {
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
    justify-content: space-between;
    align-items: center;
  }
  &__buttonsInputContainer {
    width: 70%;
  }
}

@media screen and (max-width: 768px) {
  .newChapter {
    margin: 0 10px;
    height: initial;
  }
}
