.accurateScene {
  //background-color: blue;
  //flex-grow: 1;
  height: 1rem;
  align-items: flex-end;
  display: flex;
  box-sizing: border-box;
  position: relative;
  //background-image: url("https://apaniuta.github.io/snake-game/img/down.png");
  //&:first-of-type &__first {
  //  border-left: 1px solid rgba(0, 0, 0, 0.45);
  //  height: 7px;
  //}
  &:nth-of-type(4n + 1) &__first {
    height: 4px;
    border-left: 1px solid rgba(0, 0, 0, 0.45);
  }
  &:nth-of-type(20n + 1) &__first {
    height: 7px;
    border-left: 1px solid rgba(0, 0, 0, 0.45);
  }
  &:last-of-type &__first {
    height: 7px;
    border-left: 2px solid rgba(255, 5, 5, 0.45);
  }
  &:first-of-type &__first {
    height: 10px;
    border-left: 2px solid rgba(255, 5, 5, 0.45);
  }

  &_time {
    position: absolute;
    color: rgba(0, 0, 0, 0.45);
    font-size: 10px;
    top: -7px;
    left: -3px;
    width: 15px;
    justify-content: center;
    display: flex;
  }
  //&:first-of-type &_time {
  //  left: -6px;
  //}
  &__first {
    width: 50%;
    box-sizing: border-box;
  }
  &__second {
    width: 50%;
    box-sizing: border-box;
  }
}

.accurateScene:hover {
  //width: 0;
  //height: 0;
  //border-left: 5px solid transparent;
  //border-right: 5px solid transparent;
  //border-top: 1rem solid blue;
  //flex-grow: 0;
  //background-image: url(data:/position_marker.png);
  //background: linear-gradient(to right top, transparent 50%, blue 50%) left / 50% 100% no-repeat, linear-gradient(to left top, transparent 50%, blue 50%) right / 50% 100% no-repeat;
  background: linear-gradient(to left top, transparent 50%, blue 49%) left/100%
    100% no-repeat;
}
//
.accurateScene_current {
  //width: 0;
  //height: 0;
  //border-left: 5px solid transparent;
  //border-right: 5px solid transparent;
  //border-top: 1rem solid black;
  //flex-grow: 0;
  //background: linear-gradient(to right top, transparent 50%, black 50%) left / 50% 100% no-repeat, linear-gradient(to left top, transparent 50%, black 50%) right / 50% 100% no-repeat;
  background: linear-gradient(to left top, transparent 50%, black 49%) left/100%
    100% no-repeat;
}

@media screen and (max-width: 426px) {
  .accurateScene {
    flex-grow: 1;
  }
  .accurateScene:hover {
    //height: 1.5rem;
  }
}
