.bg {
  flex-direction: column;
  display: flex;
  padding: 0 10px;
  //height: 100vh;
  //min-height: 100vh;
}

.pageColumn {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;

  //для сплитера
  height: 85vh;
}

.pageNavigation {
  display: flex;
  flex-direction: column;
}

.pageContainer {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 100%;
}

.video {
  display: flex;
  flex-direction: column;
  width: 30%;
  flex-grow: 1;
}

.inputButtons {
  display: flex;
  flex-direction: column;
  height: 100%;
  //height: fit-content;
  //width: 30%;
}

.chapters {
  display: flex;
  //width: 50%;
  height: 100%;
  justify-content: space-between;
  flex-grow: 1;
  &_column {
    flex-direction: column;
  }
  &_row {
    flex-direction: row;
  }
  &__expandChapterScroller {
    display: flex;
    width: fit-content;
    margin-bottom: 10px;
  }
  &__chapters {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    min-height: 25vh;
    //width: 50%;
    //height: fit-content;
    height: 100%;
    flex-grow: 1;
    &_column {
      width: initial;
      max-height: 40vh;
    }
    &_horizon {
      max-height: 40vh;
    }
  }
  &__rowContainer {
    display: flex;
    flex-direction: row;
  }
  &__columnContainer {
    display: flex;
    flex-direction: column;
    width: 60%;
    justify-content: space-between;
    flex-grow: 1;
  }
}

.videoContainer {
  width: auto;
  /* min-height: 600px; */
  //height: 70vh;
  position: relative;
  overflow: hidden;
  z-index: 1;
  justify-content: center;
  display: flex;
  //flex-grow: 1;
  height: 100%;
  &__video {
    width: auto;
    height: 100%;
    position: absolute;
    object-fit: cover;
  }
}

.watchPagHeader {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  &__uploadButton {
    width: 20%;
  }
  &__logoutButton {
    width: 20%;
  }
  &__title {
    margin: 0 0 0 10px;
  }
  &__menu {
    margin-left: auto;
  }
}

.blur {
  background-color: #c4c4c4;
  position: fixed;
  opacity: 0.8;
  display: flex;
  justify-content: flex-end;
}

.comments {
  display: flex;
  flex-direction: column;
  //height: 20vh;
  //border: 1px solid red;
  margin: 10px 0;
  padding: 10px;
  align-items: initial !important;
}

.appNameContainer {
  display: flex;
  flex-direction: row !important;
  height: 10vh;
  //border: 1px solid red;
  align-items: center;
  justify-content: initial !important;
  &__imgContainer {
    height: 100%;
  }
  &__img {
    height: 100%;
    max-width: initial;
    width: auto;
    border-radius: 1rem;
  }
  &__title {
    margin-left: 10px;
  }
}

.appTextContainer {
  display: flex;
  flex-direction: column;
  height: 15vh;
  //border: 1px solid red;
  margin-top: 15px;
  //overflow-y: scroll;
}

.chapterScroller {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chapterScrollerContainer {
  display: flex;
  //max-height: 35vh;
  height: 100%;
  flex-direction: column;
  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}

.gutter {
  padding: 1px 1px;
  //height: 100%;
  //width: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  &__light {
    background: rgb(249, 250, 251) !important;
  }

  &__dark {
    background: rgb(42, 46, 55) !important;
  }

  &__horizontal {
    &:hover {
      cursor: col-resize;
    }
  }
  &__vertical {
    &:hover {
      cursor: row-resize;
    }
  }
}

.appInfo {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 5px;
}

.appData {
  &_row {
    display: flex;
    flex-direction: row;
  }
  &_column {
    display: flex;
    flex-direction: column;
  }

  &__rowContainer {
    display: flex;
    flex-direction: row;
  }

  &__columnContainer {
    display: flex;
    flex-direction: column;
  }
}

.controlButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
