.dropZone {
  border: 3px dashed #3c78dc;
  border-radius: 4px;
  background: #f1f3f4;
  &__p {
    margin: 20px 0;
  }
  &:hover {
    background: transparent;
  }
}
