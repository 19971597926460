body {
  font-family: 'Inter', sans-serif;
}

.wrapper {
  max-width: 84vw;
  margin: 0 auto;
}

.homePage {

  h1 {
    font-size: 62px;
    font-weight: bold;
    line-height: 1.25;

    span {
      color: #570DF8;
    }
  }

  h2 {
    color: #570DF8;
    font-size: 24px;
    text-transform: uppercase;
    line-height: 1.5;
    font-weight: 500;
    text-align: center;
    margin-bottom: 50px;
  }

  .mainSection {
    background-color: #F5F5F5;
    background-image: url('../../assets/img/main-home-img.png');
    background-repeat: no-repeat;
    background-position: 80% 100%;

    .textWrapper {
      min-width: 430px;
      padding-top: 110px;
      padding-bottom: 56px;
      max-width: 648px;

      p {
        color: #616161;
        font-size: 28px;
        line-height: 1.5;
        font-weight: 500;
        margin-top: 30px;
      }
    }
  }

  .featuresShortSection {
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 100px;

    .featuresShortWrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 1rem;


      .featureShort {
        display: flex;
        gap: 1rem;
        flex-direction: column;
        width: 29%;
        padding: 1.5rem 0;


        .content {
          display: flex;
          gap: 1.5rem;
          min-width: 360px;

          img {
            object-fit: contain;
            max-height: 52px;
          }

          .text {
            display: flex;
            flex-direction: column;
            gap: .5rem;

            .title {
              font-weight: 500;
              font-size: 28px;
              line-height: 1.5;
            }

            .description {
              font-size: 22px;
              color: #616161;
            }
          }
        }

        //.featureShort:nth-child(1) .screenshot {
        //  transform: scale(1.08);
        //}
        //

        //
        //.featureShort:nth-child(3) .screenshot {
        //  transform: scale(1.1);
        //}




      }

      .featureShort:nth-child(2) .screenshot {
        transform: scale(1);
        position: relative;
        top: -5px;
      }

      .featureShort:nth-child(3) .screenshot {
        transform: scale(1.02);
        position: relative;
        top: -6px;
      }
    }
  }

  .customersSection {
    background: #F5F5F5;
    flex-wrap: wrap;
    margin: 80px 0;
    padding: 2.5rem 1.5rem;

    .customers {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 1rem;

      div {
        min-width: 360px;
        font-weight: 500;
        background: #fff;
        color: #212121;
        font-size: 28px;
        line-height: 1.4;
        width: 29%;
        text-align: center;
        padding: 2.5rem 1.5rem;
      }
    }
  }

  .featuresSection {
    padding: 0 0 100px;

    .items {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 1rem;

      .featureShortItem {
        width: 29%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        min-width: 360px;


        img {
          width: 100%;
          height: 90px;
          max-width: 7.5rem;
          object-fit: contain;
          margin: 0 auto;
        }

        .text {
          display: flex;
          flex-direction: column;
          gap: .5rem;
          text-align: center;

          .title {
            font-size: 28px;
            font-weight: 500;
            line-height: 1.5;
            color: #212121;
          }

          .description {
            color: #616161;
            font-size: 22px;
          }
        }


      }
    }
  }

  .footer {
    background: #F5F5F5;

    .footerWrapper {
      padding: 2.5rem 0;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 2rem;

      .copyright {
        font-size: 22px;
      }

      .links {
        font-size: 22px;
        display: flex;
        gap: 2.5rem;
      }
    }
  }
}


@media screen and (max-width: 1600px) {
  .homePage {
    .mainSection {
      background-position: 96% 100%;
    }
  }
}


@media screen and (max-width: 1390px) {
  .homePage {
    .mainSection {
      background-image: none;

      .textWrapper {
        padding: 7.5rem 0;
        text-align: center;
        max-width: none;
      }
    }

    .featuresShortSection {
      .featuresShortWrapper {
        .featureShort {
          .content {
            gap: .5rem;
            min-width: auto;

            .text {
              .title {
                font-size: 1rem;
              }

              .description {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: 768px) {
  .homePage {
    .mainSection {
      .textWrapper {
        padding-top: 110px;
        padding-bottom: 56px;
        min-width: auto;

        h1 {
          font-size: 32px;
          font-weight: bold;
          line-height: 1.25;

          span {
            color: #570DF8;
          }
        }

        p {
          font-size: 24px;
        }

        .buttonUploadVideo {
          margin: 2rem auto 0;
        }
    }


      p {
        color: #616161;
        font-size: 14px;
        line-height: 1.5;
        font-weight: 500;
        margin-top: 30px;
      }
    }

    .featuresShortSection {
      .featuresShortWrapper {
        flex-direction: column;
        align-items: center;

        .featureShort {
          width: 100%;

          .content {
            gap: 1.5rem;
          }
        }
      }
    }

    .customersSection {
      .customers {
        justify-content: center;

        div {
          min-width: auto;
          font-weight: 500;
          background: #fff;
          color: #212121;
          font-size: 28px;
          line-height: 1.4;
          width: 100%;
          text-align: center;
          padding: 2.5rem 1.5rem;
        }
      }
    }

    .featuresSection {
      .items {
        gap: 3rem;

        .featureShortItem {
          width: 100%;
          min-width: auto;
        }
      }
    }
  }

  .links {
    display: flex;
    flex-direction: column;
  }
}


.buttonUploadVideo {
  display: inline-block;
  width: auto;
  text-transform: none;
  border-radius: 2rem;
  padding: .75rem 1.25rem;
  height: auto;
  min-height: auto;
  margin-top: 1.5rem;

}
