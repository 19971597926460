.chapter {
  display: flex;
  flex-direction: column;
  border: 2px solid transparent;
  border-radius: 4px;
  //min-width: 90px;
  //max-height: 20vh;
  width: 16%;
  //width: 11vw;
  position: relative;
  box-sizing: border-box;
  justify-content: space-between;
  height: fit-content;
  &_h {
    width: 50%;
  }
  &_watchV {
    width: 25%;
  }
  &_watchH {
    width: 50%;
  }
  &_watchHFull {
    width: 100%;
  }
  &__p {
    //color: #3c7ae4;
    margin: 0;
    max-width: 100%;
    overflow: hidden;
    align-items: center;
    display: flex;
    //min-height: 20px;
    height: 20px;
    white-space: nowrap;
  }
  &__imageContainer {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  &__image {
    width: auto;
    max-width: initial;
    //height: 85%;
    //height: 24vh;
  }
  &_active {
    border: 2px solid red;
  }
  &:hover {
    border: 2px solid #570df8;
    box-sizing: border-box;
  }
}
