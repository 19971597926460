.ChapterButtonsBlock {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  padding-left: 5px;
  //background-color: #d6d8d9;
  //box-shadow: 0 0 0 2px rgba(24,144,255, 0.2);
  padding-bottom: 5px;
  border-radius: 2px;
}
