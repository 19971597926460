.chapter {
  display: flex;
  flex-direction: row;
  &__time {
    cursor: pointer;
    width: 60px;
  }
  &__title {
    margin-left: 10px;
    overflow: hidden;
  }
}
