.library {
  h1 {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.25;
    //color: #570DF8;
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }

  h2 {
    color: #570DF8;
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 50px;
  }
}
