.newChapter {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0 50px;
  height: 10vh;
  justify-content: space-between;

  &__inputContainer {
    display: flex;
  }
  &__p {
    margin: 0;
  }
  &__buttonsInputContainer {
    width: 70%;
    padding-right: 10px;
  }
  &__buttonsContainer {
    width: 50%;
    margin-left: auto;
    //height: 25px;
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .newChapter {
    margin: 0;
    height: initial;
    width: 100%;
    &__buttonsContainer {
      width: 100%;
      margin-left: initial;
      height: initial;
      display: flex;
    }
  }
}
