.registerPage {
  flex-direction: column;
  display: flex;
  padding: 0 10px;
  align-items: center;
  .registerForm {
    display: flex;
    flex-direction: column;
    max-width: 350px;
    width: 100%;
    &__label {
      margin-top: 10px;
    }
    &__label:first-of-type {
      margin-top: 0;
    }
    &__errorText {
      margin-top: -24px;
      color: red;
      position: relative;
      bottom: -26px;
      left: 5px;
      background: white;
      display: flex;
      width: fit-content;
    }
    &_error {
      border: 2px solid red;
      border-radius: 4px;
      padding: 0 6px;
    }
  }
}
