.container {
  height: 20px;
  width: 100%;
  background-color: #e0e0de;
  border-radius: 50px;

  .filter {
    text-align: right;
    height: 100%;
    border-radius: inherit;
    transition: width 0.1s ease-in-out;
    .label {
      padding: 10px;
      margin-bottom: -4px;
      color: white;
    }
  }
}
