.innacurateScene {
  display: flex;
  flex-direction: column;
  //border: 2px solid transparent;
  //border-radius: 4px;
  //min-width: 90px;
  transition: 0.5s ease-in-out;

  &:hover {
    //border: 2px solid green;
    box-sizing: border-box;
    filter: brightness(40%);
  }
  &_active {
    border: 2px solid red;
  }
  &__p {
    color: #3c7ae4;
    margin: 0;
  }
  &__image {
    width: 100%;
    max-width: 50px;
    height: auto;
    flex-grow: 1;
  }
}

@media screen and (max-width: 426px) {
  .innacurateScene__image {
    width: initial;
    max-width: 70px;
    height: auto;
  }
}
