.video {
  display: flex;
  flex-direction: column;
  //width: 30%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  max-height: 85vh;

  //для делителя
  height: 100%;
  &_horizontal {
    //width: 50%;
    //для адаптива с делителем
    //min-width: 50%;
    //max-width: 50%;
  }
}

.videoContainer {
  width: 100%;
  /* min-height: 600px; */
  //height: 70vh;
  position: relative;
  overflow: hidden;
  z-index: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
  &__video {
    position: absolute;
    object-fit: cover;
    &_vertical {
      width: auto;
      height: 100%;
    }
    &_horizontal {
      width: 100%;
      height: auto;
    }
  }
}

.blur {
  background-color: #c4c4c4;
  position: fixed;
  opacity: 0.8;
  display: flex;
  justify-content: flex-end;
}
