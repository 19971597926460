.header {
  //margin-top: 10px;
  &__button {
    text-transform: initial !important;
  }
  &__logo {
    margin-right: 17px;
  }
  &__border {
    margin: 0 10px;
  }
  &__dropdown {
    display: inline-block;
    position: relative;
    &_open {
      outline: 2px solid transparent;
      outline-offset: 2px;
    }
  }

  .profile {
    span {display: none;}
  }
}

@media (max-width: 768px) {
  .header {
    display: flex;
    flex-direction: column;
    align-items: start !important;

    & > div,
    & > span{
      display: flex;
      flex-direction: column;
      align-items: start;
      margin-right: 0;
      margin-left: 0;
    }

    .logoRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }


    .profile {
      display: flex;
      align-items: center;
      padding: 1rem;
      font-weight: bold;
      margin: 0;

      .avatar {
        height: 24px;
        width: 24px;
        object-fit: contain;
      }

      span {
        cursor: pointer;
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        text-align: center;
        -webkit-user-select: none;
        user-select: none;
        transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
        transition-duration: .15s;
        transition-duration: .2s;
        transition-timing-function: cubic-bezier(.4, 0, .2, 1);
        border-radius: var(--rounded-btn, .5rem);

        font-size: .875rem;

        padding-right: 1rem;

        font-weight: 600;
        text-transform: uppercase;
        text-transform: var(--btn-text-case, uppercase);
        border-width: var(--border-btn, 1px);
        animation: button-pop var(--animation-btn, .25s) ease-out;
        --tw-bg-opacity: 1;
        display: inline-block;
        background-color: transparent;
        border-color: transparent;
        border-width: 1px;
        color: currentColor;
        text-transform: initial !important;

      }
    }


    .dropdown  {
      left: 0;
      right: auto;
    }
  }
}
