.blurScene {
  //background-color: gray;
  flex-grow: 1;
  height: 1rem;
  cursor: pointer;
  //&:hover {
  //  border: 1px solid green;
  //  height: 2rem;
  //  //min-width: 0.5rem;
  //  border-radius: 4px;
  //  background-color: purple;
  //}
}

.active {
  background-color: mediumvioletred;
  border-top: 2px solid purple;
  border-bottom: 2px solid purple;
}

.firstTime {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-left: 2px solid purple;
}

.lastTime {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  border-right: 2px solid purple;
}

//.accurateScene::before {
//  content: ".";
//  visibility: hidden;
//}

@media screen and (max-width: 426px) {
  .blurScene:hover {
    height: 1.5rem;
  }
}
