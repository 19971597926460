.chapter {
  margin-top: 10px;
  position: relative;
  &__deleteContainer {
    position: relative;
    right: 5px;
    top: 7px;
  }
  &__label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__time {
    cursor: pointer;
  }
  &__inputContainer {
    width: 70%;
  }
  &:first-of-type {
    margin-top: 5px;
  }
  &:last-of-type {
    margin-bottom: 5px;
  }
}
