.navigationBlur {
  display: flex;
  flex-direction: row;
  height: 2rem;
  align-items: center;
  position: relative;
}

@media screen and (max-width: 426px) {
  .navigationBlur {
    height: 1.5rem;
  }
}
