.ruler {
  position: relative;
  width: 100%;
  height: 14px;
  display: flex;
  margin-bottom: 5px;
  margin-top: 5px;
  align-items: flex-end;
  display: none;
}

.ruler__element {
  border-right: 1px solid rgba(0, 0, 0, 0.45);
  //background-color: #f1f3f4;
  flex-grow: 1;
  height: 4px;
  position: relative;
}

.ruler__line {
  color: rgba(0, 0, 0, 0.45);
  position: absolute;
  left: -6px;
  bottom: 10px;
  font-size: 10px;
}

.ruler__element:first-of-type {
  .ruler__line {
    left: 0;
  }
}

//.ruler__element::before {
//  content: ".";
//  visibility: hidden;
//}

.ruler__element:nth-of-type(5n) {
  height: 7px;
}

.ruler__element:nth-of-type(5n)::after {
  position: absolute;
  //bottom: -20px;
  //content: "10";
}

.ruler__element:last-of-type {
  border-right: initial;
}
