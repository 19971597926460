.chapterModalContainer {
  display: flex;

  .background {
    width: 100%;
    min-height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
  }

  .modalContainer {
    background-color: white;
    position: absolute;
    top: calc((100vh - 200px) / 2);
    left: calc((100vw - 425px) / 2);
    right: calc((100vw - 425px) / 2);
    border-radius: 4px;
    padding: 10px;
    z-index: 4;

    &__title {
      margin-top: 0;
      margin-bottom: 10px;
    }

    &__buttonContainer {
      height: 11vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__subtitle {
      margin: 0;
    }
  }

  @media screen and (max-width: 425px) {
    .modalContainer {
      left: calc((100vw - 300px) / 2);
      right: calc((100vw - 300px) / 2);
    }
  }
}
