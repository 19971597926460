.container {
  width: 17px;
  display: flex;
  position: absolute;
  right: 0;
  //top: -1px;
  cursor: pointer;

  &__img {
    height: auto;
    width: 100%;
    opacity: 0.7;
  }
}
