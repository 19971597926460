.wholeScene {
  display: flex;
  flex-direction: column;
  border: 2px solid transparent;
  border-radius: 4px;
  //min-width: 90px;
}

.wholeScene:hover {
  border: 2px solid green;
  box-sizing: border-box;
}

.wholeScene_active {
  border: 2px solid red;
}

.wholeScene__image {
  width: auto;
  //height: 40vh;
  height: 92%;
  max-width: initial;
}

.current {
  border: 2px solid green;
}
