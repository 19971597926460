.screen {
  display: flex;
  flex-direction: column;
  &__smallButtonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
