.privacyPolicy {
  margin-bottom: 5rem;

  h1 {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.25;
    //color: #570DF8;
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }

  h2 {
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.5;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  ul {
    list-style-type: disc;
    margin: 1rem 0;
    padding-left: 2.5rem;
  }
}
