.firstMainScreen {
  display: flex;
  flex-direction: column;
  position: relative;
}

.video {
  width: auto;
  /* min-height: 600px; */
  height: 60vh;
  position: relative;
  overflow: hidden;
  z-index: 1;
  justify-content: center;
  display: flex;
}

.videoContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.navigation {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.smallButtonContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 2;
  right: 0;
  top: 10px;
  width: 15%;
}
