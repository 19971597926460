.modalContainer {
  //position: relative;
}

.modalBackground {
  width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.modal {
  background-color: white;
  position: absolute;
  top: calc((100vh - 200px) / 2);
  left: calc((100vw - 425px) / 2);
  right: calc((100vw - 425px) / 2);
  border-radius: 4px;
  padding: 1.5rem;
  z-index: 3;
  &__title {
    font-weight: bold;
    margin-top: 0;
    padding-bottom: 1rem;
    border-width: 0px 0px thin;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.12);
  }
  &__subTitle {
    margin: 1.25rem 0 1rem;
  }
}

.off {
  display: none;
}

@media screen and (max-width: 425px) {
  .modal {
    left: calc((100vw - 300px) / 2);
    right: calc((100vw - 300px) / 2);
  }
}


.input {
  border: 1px solid transparent;
  border-radius: 2px;
  height: 30px;
  padding: 0 10px 0 10px;
  background-color: #f1f3f4;
  box-sizing: border-box;
  width: 100%;

  outline: 2px solid transparent;
  outline-offset: 2px;
  box-shadow: 0 0 0 2px hsl(var(--b1)), 0 0 0 4px hsla(var(--bc) / .2);
  &:focus-visible {
    outline: none;
    //border: 2px solid #4285f4;
  }
  &__cursorInitial {
    cursor: initial;
  }
}
