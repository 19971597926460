.chapterSelector {
  display: flex;
  flex-direction: column;
  //min-width: 40%;
  max-height: 70vh;
  overflow-y: scroll;
  //padding-right: 5px;
  padding-left: 5px;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #aaa;
  }
}

@media screen and (max-width: 426px) {
  .chapterSelector {
    max-height: 30vh;
    margin-bottom: 10px;
  }
}
