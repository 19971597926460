.desktop {
  position: absolute;
  object-fit: cover;
  &_vertical {
    width: auto;
    //height: 100%;
    max-height: 100%;
  }
  &_horizontal {
    width: 100%;
    height: auto;
  }
}

.mobile {
  position: absolute;
  object-fit: cover;
  &_vertical {
    width: auto;
    height: 100%;
  }
  &_horizontal {
    width: 100%;
    height: auto;
  }
}
