.bg {
  flex-direction: column;
  display: flex;
  padding: 0 10px;
  //height: 100vh;
  min-height: 100vh;
}

.pageColumn {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  height: 85vh;
  max-height: 85vh;
}

.pageNavigation {
  display: flex;
  flex-direction: column;
}

.pageContainer {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.video {
  display: flex;
  flex-direction: column;
  width: 30%;
  flex-grow: 1;
}

.inputButtons {
  display: flex;
  flex-direction: column;
  //height: fit-content;
  //width: 30%;
  &_column {
    grid-row-start: 1;
    grid-row-end: 3;
  }
}

.chapters {
  display: grid;
  grid-template-columns: 40% 60%;
  grid-template-rows: 50%;
  width: 25%;
  justify-content: space-between;
  flex-grow: 1;
  max-height: 73vh;
  &_column {
    flex-direction: column;
  }
  &_row {
    flex-direction: row;
  }
  &__chapters {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    min-height: 25vh;
    //width: 50%;
    height: fit-content;
    flex-grow: 1;
    max-height: 100%;
    &_column {
      width: initial;
      max-height: 40vh;
    }
    &_horizon {
      max-height: 40vh;
    }
  }
  &__rowContainer {
    display: flex;
    flex-direction: row;
  }
  &__columnContainer {
    display: flex;
    flex-direction: column;
    width: 60%;
    justify-content: space-between;
    flex-grow: 1;
  }
}

.videoContainer {
  width: auto;
  /* min-height: 600px; */
  //height: 70vh;
  position: relative;
  overflow: hidden;
  z-index: 1;
  justify-content: center;
  display: flex;
  //flex-grow: 1;
  height: 100%;
  &__video {
    width: auto;
    height: 100%;
    position: absolute;
    object-fit: cover;
  }
}

//.watchPagHeader {
//  display: flex;
//  align-items: center;
//  margin-bottom: 5px;
//  &__uploadButton {
//    width: 20%;
//  }
//  &__logoutButton {
//    width: 20%;
//  }
//  &__title {
//    margin: 0 0 0 10px;
//  }
//}

.watchPagHeader {
  margin: 0 -10px 10px;
}

.blur {
  background-color: #c4c4c4;
  position: fixed;
  opacity: 0.8;
  display: flex;
  justify-content: flex-end;
}

.sceneScrollerContainer {
  &_row {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.watchPagHeader__uploadButton {
  max-width: 320px;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}


.projectName {
  //display: flex;
}
