.navigationAccurate {
  display: flex;
  flex-direction: row;
  height: 1rem;
  align-items: center;
  margin-top: 5px;
}

@media screen and (max-width: 426px) {
  .navigationAccurate {
    height: 1rem;
  }
}
