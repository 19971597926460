.button {
  margin-bottom: 5px;
  margin-top: 6px;
  height: 30px;
  background: #fff;
  border-radius: 4px;
  border: 2px solid #1890ff;
  width: 100%;
  -webkit-transition: box-shadow 0.08s linear,
    min-width 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  transition: box-shadow 0.08s linear,
    min-width 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  &:hover {
    box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3),
      0 4px 8px 3px rgba(60, 64, 67, 0.15%);
    background-color: #fafafb;
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  .icon {
    margin-right: 8px;
  }
  .text {
    font-weight: bold;
    color: #1890ff;
    margin-top: 2px;
  }
}
