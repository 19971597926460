
.wrapper {
  right: 16px;
  top: 80px;
  position: absolute;
  display: flex;
  gap: 10px;
  //
  //@include mobile {
  //  display: none;
  //}

  a {
    transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
    width: fit-content;
    height: 43.2px;
    border-radius: 10px;
    display: block;
    background: #fff;

    &:hover {
      box-shadow: 0px 2px 8px 0px rgba(34, 60, 80, 0.2);
    }

    &:active {
      transform: scale(0.98);
    }
  }
}
